import React from "react";
import img from "../../assets/img/image 1.png";
import { Box, Button, Typography ,useTheme} from "@mui/material";
import button from "../../assets/img/download-button-gif-12 1.png";
import vote from "../../assets/img/image 4.png";
import mainvote from "../../assets/img/image 6.png";
import vote2 from "../../assets/img/image 5.png";
import phone from "../../assets/img/Group 4.png";

function Content() {
    const theme = useTheme();
  return (
    <Box>
      {/* Full Width Image */}
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <img src={img} alt="img" style={{ width: "100%" }} />
      </Box>

      {/* Section with Download Button */}
      <Box
        sx={{
          backgroundColor: "#E2FFBA",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          component="p"
          sx={{
            color: "#318C36",
            fontWeight: "bold",
            marginTop: "20px",
            fontSize: { xs: "1.2rem", md: "1.5rem" }, // responsive font size
          }}
        >
          चुनाव के सही नतीजे जानने के लिए <br />
          अभी ज्वाइन करें
        </Typography>

        {/* Download Button */}
        <Button onClick={()=>window.open('https://server.phalodibazar.in.net/misc/apk')}>
          <img
            src={button}
            style={{ width: "250px", marginTop: "18px", cursor: "pointer" }}
            alt="Download Button"
          />
        </Button>
      </Box>

      {/* What is Lorem Ipsum Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: { xs: "20px", md: "40px" }, // responsive padding
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: { xs: "1.5rem", md: "2rem" }, // responsive heading size
          }}
        >
          Election
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
          1.फलोदी बाजार चुनावों में सही नतीजा देने वाला एशिया का सबसे बड़ा बाजार है।

        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
          2. जब आप 2 करोड़ के इनाम वाले बैटल में टीम बनाते है तो उसमें प्वाइंट मिलने का आधार जीतने वाले प्रत्याशी के 1 वोट का एक प्वाइंट होता है । मान लो कोई 5000 वोट से जीतना है तो और आपने उसको 18 बैटल में शामिल किया था तो आपको एक कैंडिडेट के 5000 प्वाइंट मिलेंगे। 
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
3. अगर आप 2 करोड़ के इनाम वाले बैटल में किसी हारने वाले कैंडिडेट को शामिल कर लेते हो तो जितने वोट से वो हारेगा उतने ही प्वाइंट आपके 18 वाली बैटल टीम प्वाइंट से माइनस किए जाएंगे।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
4. CM कैंडिडेट में संभावित उम्मीदवार शामिल किए गए है उनके भाव उन पर लगाई गई बैट और कितने लोगो द्वारा उनको चुना गया उस आधार पर ही उनके भाव कम ज्यादा किए जाते है ।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
5.असेंबली कैंडिडेट में आप विधानसभा और पार्टी चुनकर किस पर क्या भाव चल रहे है यह ग्राउंड सर्वे और ऑनलाइन बैट पर निर्भर है । असेंबली कैंडिडेट में भाव उपर नीचे तभी करते है जब ग्राउंड टीम जमीनी रिपोर्ट लेकर देती है ।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
6. फलोदी बाजार के द्वारा दिए गए भाव और आंकड़े आज तक सभी चुनावों में सटीक बैठते आए है।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
7.फलोदी बाजार की शुरूआत 1975 के आस पास हुई थी जो वर्तमान में पूरे एशिया में सटीक आंकड़े देने की वजह से काफी फैमस है ।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
8.फलोदी बाजार हमेशा ऑफलाइन काम करता आया है पर डिजीटल के इस दौर में फलोदी बाजार भी अब ऑनलाइन आप सब के बीच उपस्थित है ।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
9. आपको किसी भी कैंडिडेट के बारे में लगता है की यह जीतेंगे ही जीतेंगे तो आप उस पर पैसे लगा कर आराम से जीत सकते है।

        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" }, // responsive text size
          }}
        >
10. आपको फलोदी बजार में किसी भी प्रकार की हेल्प की जरूरत है तो आप हमारे टेलीग्राम पर संपर्क कर सकते है।
        </Typography>
      </Box>

      {/* Second Lorem Ipsum Section */}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: { xs: "20px", md: "40px" }, // responsive padding
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: { xs: "1.5rem", md: "2rem" },
          }}
        >
          What is Lorem Ipsum?
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            marginBottom: "20px",
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" },
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s...
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: "left",
            maxWidth: "1420px",
            fontSize: { xs: "0.9rem", md: "1rem" },
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s...
        </Typography>
      </Box> */}

      {/* Vote Image */}
      <Box>
        <img src={vote} alt="vote" style={{ width: "100%" }} />
      </Box>

      {/* Text and Image Section */}
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, 
            alignItems: "center",
            justifyContent: "space-between",
            padding: { xs: "20px", md: "40px" },
          }}
        >
          {/* Left Text */}
          <Box sx={{ flex: 1, paddingRight: { md: "20px" }, marginBottom: { xs: "20px", md: "0" } }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: { xs: "1.5rem", md: "2rem" },
              }}
            >
              What is Lorem Ipsum?
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" }, textAlign: "left" }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry...
            </Typography>
          </Box>

          {/* Right Image */}
          <Box sx={{ flex: 1 }}>
            <img src={mainvote} alt="vote" style={{ width: "100%" }} />
          </Box>
        </Box>
      </Box>

      {/* Image and Text Section */}
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" }, 
            alignItems: "center",
            justifyContent: "space-between",
            padding: { xs: "20px", md: "40px" },
          }}
        >
          {/* Left Image */}
          <Box sx={{ flex: 1 }}>
            <img src={vote2} alt="vote" style={{ width: "70%" }} />
          </Box>

          {/* Right Text */}
          <Box sx={{ flex: 1, paddingRight: { md: "20px" } }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: { xs: "1.5rem", md: "2rem" },
              }}
            >
              What is Lorem Ipsum?
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" }, textAlign: "left" }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry...
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        <img
          src={phone}
          alt="phone"
          style={{ width: "100%", maxWidth: "700px" }}
        /> 
        <Box
            sx={{
                backgroundColor: "#E2FFBA",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "70%",
                width: "100%",
                height: "230px",
                [theme.breakpoints.down('sm')]: {
                  height: '130px', 

                },
              }}
        >
          <Typography
            variant="h5"
            component="p"
            sx={{
              color: "#318C36",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: { xs: "1.2rem", md: "1.5rem" },
            }}
          >
            चुनाव के सही नतीजे जानने के लिए <br />
            अभी ज्वाइन करें
          </Typography>

          {/* Button */}
          <Button onClick={()=>window.open('https://server.phalodibazar.in.net/misc/apk')}>
            <img
              src={button}
              style={{ width: "200px", cursor: "pointer" }}
              alt="Download Button"
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Content;
