import React from 'react';
import { Box, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#000", // Dark background
        padding: { xs: "20px", md: "40px" }, // Responsive padding
        textAlign: "center",
        borderTop: "2px solid #00bfff", // Light blue border on top (optional)
        mt: 4,
      }}
    >
      {/* Follow Now Text */}
      <Typography
        variant="h6"
        sx={{
          color: "#fff",
          marginBottom: "20px",
          fontSize: { xs: "1.2rem", md: "1.5rem" }, // Responsive font size
        }}
      >
        Follow now
      </Typography>

      {/* Social Media Icons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "15px", md: "25px" }, // Responsive gap between icons
          flexWrap: "wrap", // Ensure icons wrap on smaller screens
        }}
      >
        <FacebookIcon style={{ color: "#4267B2", fontSize: "40px" }} />
        <TwitterIcon style={{ color: "#1DA1F2", fontSize: "40px" }} />
        <YouTubeIcon style={{ color: "#FF0000", fontSize: "40px" }} />
        <LinkedInIcon style={{ color: "#0077B5", fontSize: "40px" }} />
        <InstagramIcon style={{ color: "#E1306C", fontSize: "40px" }} />
        <PinterestIcon style={{ color: "#E60023", fontSize: "40px" }} />
      </Box>
    </Box>
  );
};

export default Footer;
