import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material'; // For handling responsiveness
import logoimg from "../../assets/img/image 2.png";
import bazar from "../../assets/img/image 3.png";

const styles = {
  logo: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
  appBar: {
    backgroundColor: '#000',
    justifyContent: 'space-between',
    boxShadow: 'none', // No shadow for a cleaner look
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    margin: 'auto',
  },
  logoImage: {
    width: '70px',
  },
  bazarImage: {
    width: '120px',
    marginLeft: '10px', // Space between the two logos
  },
  downloadButton: {
    backgroundColor: '#FF6601',
    color: '#FFF',
    borderRadius: '20px',
    padding: '10px 20px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#FF8500',
    },
  },
  downloadButtonMobile: {
    backgroundColor: '#FF6601',
    color: '#FFF',
    borderRadius: '20px',
    padding:'10px',
    fontSize: '0.6rem',  
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#FF8500',
    },
  },
};

const NavBar = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); 
  return (
    <AppBar position="static" style={styles.appBar}>
      <Toolbar style={styles.toolbar}>
        {/* Logo Section */}
        <Box style={styles.logo}>
          <IconButton edge="start" color="inherit" aria-label="logo">
            <img
              src={logoimg}
              alt="Logo"
              style={styles.logoImage}
            />
          </IconButton>
          <img
            src={bazar}
            alt="Bazar"
            style={styles.bazarImage}
          />
        </Box>

        {/* Download Button */}
        <Button
        onClick={()=>window.open('https://server.phalodibazar.in.net/misc/apk')}
          style={isMobile ? styles.downloadButtonMobile : styles.downloadButton}
          variant="contained"
        >
          Download Now
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
